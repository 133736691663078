import React from "react"

import MobileDevelopmentIllustration from "../../../../assets/images/services/mobile-development-illustration.svg"

// Mobile app development
import FlutterIcon from "../../../../assets/images/technologies/Flutter.svg"
import ReactIcon from "../../../../assets/images/technologies/React.svg"

// import components
import ServiceBrief from "../ServiceBrief"

export default () => {
  return (
    <ServiceBrief
      key="mobile-service"
      heroIllustration={MobileDevelopmentIllustration}
      accentColor="purple"
      alignLeft={true}
      serviceHeading="Mobile App Development"
      serviceSubheading="<span> We provide end-to-end development services for mobile apps that are in line with your organizational needs. We build hybrid solutions to reach a more targeted audience.</span>"
      subdomains={[
        {
          heading: "Technologies & Tools",
          description:
            "<span>Frameworks that we use to develop Mobile Apps. </span>",
          tools: [
            { icon: ReactIcon, name: "React Native" },
            { icon: FlutterIcon, name: "Flutter" }
          ]
        }
      ]}
    />
  )
}
