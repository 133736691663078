import React from "react"

import UiUxIllustration from "../../../../assets/images/services/UI-UX-illustration.svg"

// Design tools
import FigmaIcon from "../../../../assets/images/technologies/Figma.svg"
import FramerIcon from "../../../../assets/images/technologies/Framer.svg"
import MiroIcon from "../../../../assets/images/technologies/Miro.svg"
import HubspotIcon from "../../../../assets/images/technologies/Hubspot.svg"
import UserbitIcon from "../../../../assets/images/technologies/Userbit.svg"
import InvisionIcon from "../../../../assets/images/technologies/Invision.svg"
import AdobeXDIcon from "../../../../assets/images/technologies/AdobeXD.svg"
import IllustratorIcon from "../../../../assets/images/technologies/illustrator.svg"
import PhotoshopIcon from "../../../../assets/images/technologies/Photoshop.svg"

// import components
import ServiceBrief from "../ServiceBrief"

export default () => {
  return (
    <ServiceBrief
      key="ui-ux-service"
      heroIllustration={UiUxIllustration}
      accentColor="yellow"
      alignLeft={false}
      serviceHeading="UI/UX Design"
      serviceSubheading="<span> UI/UX design is the catalyst behind the success of any web or mobile app. We start from conceptualization, wireframing, visual identity, and UX design; Our designers push themselves for timely delivery of appealing websites and mobile apps resulting in maximum user engagement. </span>"
      subdomains={[
        {
          heading: "UI Design",
          description: "<span>Our preferred tools for UI Design</span>",
          tools: [
            { icon: FigmaIcon, name: "Figma" },
            { icon: AdobeXDIcon, name: "Adobe XD" },
            { icon: FramerIcon, name: "Framer" },
            { icon: InvisionIcon, name: "Invision Studio" },
            { icon: IllustratorIcon, name: "Illustrator" },
            { icon: PhotoshopIcon, name: "Photoshop" }
          ]
        },
        {
          heading: "UX Design",
          description: "<span>Some of our preferred UX Design tools</span>",
          tools: [
            { icon: MiroIcon, name: "Miro" },
            { icon: UserbitIcon, name: "Userbit" },
            { icon: HubspotIcon, name: "Hubspot" },
            { icon: InvisionIcon, name: "Invision" }
          ]
        }
      ]}
    />
  )
}
