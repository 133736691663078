import React from "react"

// Import components
import SEO from "../components/global/seo"
import GlobalStyles from "../components/global/GlobalStyles"
import Header from "../components/organisms/Header"
import CTASection from "../components/organisms/CTASection"
import Footer from "../components/organisms/Footer"
import ServicesPageLayout from "../components/layout/ServicesPage"

const LandingPage = () => {
  return (
    <>
      <GlobalStyles />
      <SEO title="Contact Us" />
      <Header />
      <ServicesPageLayout />
      <CTASection />
      <Footer />
    </>
  )
}

export default LandingPage
