import React from "react"

import ChatbotIllustration from "../../../../assets/images/services/chatboot-illustration.svg"

// Design sprint tools
import AlexaIcon from "../../../../assets/images/technologies/Alexa.svg"
import GoogleAsistantIcon from "../../../../assets/images/technologies/GoogleAsistant.svg"
import Dialogflow from "../../../../assets/images/technologies/Dialogflow.svg"

// import components
import ServiceBrief from "../ServiceBrief"

export default () => {
  return (
    <ServiceBrief
      key="chat-bot"
      heroIllustration={ChatbotIllustration}
      accentColor="dark"
      alignLeft={false}
      serviceHeading="Chat Bots"
      serviceSubheading="<span> Chatbots are software applications that use artificial intelligence & natural language processing to understand what a human wants, and guides them to their desired outcome with as little work for the end user as possible. Like a virtual assistant for your customer experience touchpoints.</span>"
      subdomains={[
        {
          heading: "Technologies & Tools",
          description:
            "<span>Our preferred tools and technologies to develop conversational platform.</span>",
          tools: [
            { icon: GoogleAsistantIcon, name: "Google Assistant" },
            { icon: AlexaIcon, name: "Alexa" },
            { icon: Dialogflow, name: "Dialogflow" }
          ]
        }
      ]}
    />
  )
}
