import React from "react"
import styled from "@emotion/styled"
import withTheme from "@styled-system/css"

// Import assets
import DepthInfinityElement1 from "../../../assets/images/illustrations/depth-infinity-element1.svg"

// Import components
import WebServices from "./ServiceDomains/WebServices"
import UiUxServices from "./ServiceDomains/UiUxServices"
import BrandingServices from "./ServiceDomains/BrandingServices"
import DesignSprintService from "./ServiceDomains/DesignSprintService"
import MobileServices from "./ServiceDomains/MobileServices"
import ChatbotServices from "./ServiceDomains/ChatbotServices"

// Media queries utils
import { sm, md, lg, xl } from "../../../utils/mediaQueries"

const ServiceSection = styled.div`
  display: block;
  width: 100%;
  overflow: hidden;

  background-image: url(${DepthInfinityElement1});
  background-position: right -10rem top 50%;
  background-size: 26rem auto;
  background-repeat: no-repeat;

  ${lg} {
    background-position: right -8rem top 87.5%;
    background-size: 20rem auto;
  }

  ${sm} {
    background-position: right -5rem top 70%;
    background-size: 12rem auto;
  }
`

const ServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  max-width: 1440px;
  margin: 0px auto;
  padding: 20rem 7rem 14rem;

  ${xl} {
    padding: 10rem 4rem;
  }

  ${sm} {
    padding: 6rem 2rem;
  }
`

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  max-width: 90rem;

  ${xl} {
    max-width: 80rem;
  }
`

const PageHeading = styled.h1`
  font-weight: 700;
  line-height: 1.25;
  text-align: center;

  ${withTheme({
    fontSize: "h1",
    color: "dark",
    fontFamily: "primary"
  })}

  ${xl} {
    ${withTheme({
      fontSize: "h2"
    })}
  }

  ${sm} {
    ${withTheme({
      fontSize: "h3"
    })}
  }
`

const PageDescription = styled.h4`
  margin-top: 2rem;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;

  ${withTheme({
    fontSize: "h4",
    color: "dark",
    fontFamily: "primary"
  })}

  ${lg} {
    ${withTheme({
      fontSize: "h5"
    })}
  }

  ${sm} {
    margin-top: 1rem;
    ${withTheme({
      fontSize: "p"
    })}
  }
`

const ServiceBriefSection = styled.div`
  width: 100%;
  margin-top: 14rem;

  ${xl} {
    margin-top: 10rem;
  }

  ${lg} {
    margin-top: 8rem;
  }

  ${sm} {
    margin-top: 4rem;
  }
`

const ServiceBriefContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  & > * {
    &:not(:last-of-type) {
      margin-bottom: 14rem;
    }
  }

  ${xl} {
    & > * {
      &:not(:last-of-type) {
        margin-bottom: 10rem;
      }
    }
  }

  ${sm} {
    & > * {
      &:not(:last-of-type) {
        margin-bottom: 6rem;
      }
    }
  }
`

export default props => {
  return (
    <ServiceSection>
      <ServiceContainer>
        <PageHeader>
          <PageHeading>Web & Mobile Software Development Services</PageHeading>
          <PageDescription>We provide services you can rely on</PageDescription>
        </PageHeader>
        <ServiceBriefSection>
          <ServiceBriefContainer>
            <WebServices />
            <UiUxServices />
            <BrandingServices />
            <DesignSprintService />
            <MobileServices />
            <ChatbotServices />
          </ServiceBriefContainer>
        </ServiceBriefSection>
      </ServiceContainer>
    </ServiceSection>
  )
}
