import React from "react"

import WebServicesIllustration from "../../../../assets/images/services/web-development-illustration.svg"

// Frontend tools
import Html5Icon from "../../../../assets/images/technologies/Html5.svg"
import CSS3Icon from "../../../../assets/images/technologies/CSS3.svg"
import SassIcon from "../../../../assets/images/technologies/Sass.svg"
import GulpIcon from "../../../../assets/images/technologies/Gulp.svg"
import WebpackIcon from "../../../../assets/images/technologies/Webpack.svg"
import ReactIcon from "../../../../assets/images/technologies/React.svg"
import GatsbyIcon from "../../../../assets/images/technologies/Gatsby.svg"
import NextJsIcon from "../../../../assets/images/technologies/NextJs.svg"
import GraphqlIcon from "../../../../assets/images/technologies/Graphql.svg"
import StorybookIcon from "../../../../assets/images/technologies/Storybook.svg"

// Backend tools
import NodejsIcon from "../../../../assets/images/technologies/node-js.svg"
import AWSIcon from "../../../../assets/images/technologies/aws.svg"
import MongoDBIcon from "../../../../assets/images/technologies/MongoDB.svg"
import MySQLIcon from "../../../../assets/images/technologies/mysql.svg"

// import components
import ServiceBrief from "../ServiceBrief"

export default () => {
  return (
    <ServiceBrief
      key="web-service"
      heroIllustration={WebServicesIllustration}
      accentColor="blue"
      alignLeft={true}
      serviceHeading="Web Development"
      serviceSubheading="<span> We offer comprehensive and end-to-end solutions for your Web development projects. Static, Dynamic, Progressive Web Apps, E-Commerce – we’re proficient in developing all kind of web applications. </span>"
      subdomains={[
        {
          heading: "Frontend Development",
          description: `<span>We architect and develop outstanding web applications using these web technologies and tools.<span>`,
          tools: [
            { icon: Html5Icon, name: "HTML5" },
            { icon: CSS3Icon, name: "CSS3" },
            { icon: SassIcon, name: "Sass" },
            { icon: GulpIcon, name: "Gulp" },
            { icon: WebpackIcon, name: "Webpack" },
            { icon: ReactIcon, name: "React" },
            { icon: GatsbyIcon, name: "Gatsby" },
            { icon: NextJsIcon, name: "Next JS" },
            { icon: StorybookIcon, name: "Storybook" }
          ]
        },
        {
          heading: "Backend Development",
          description: `<span>We develop robust, secure and scalable back-end solutions using these technologies and tools.</span>`,
          tools: [
            { icon: NodejsIcon, name: "Node JS" },
            { icon: AWSIcon, name: "AWS" },
            { icon: GraphqlIcon, name: "Graphql" },
            { icon: MongoDBIcon, name: "MongoDB" },
            { icon: MySQLIcon, name: "MySQL" }
          ]
        }
      ]}
    />
  )
}
