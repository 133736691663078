import React from "react"

import BrandingIllustration from "../../../../assets/images/services/branding-service-illustration.svg"

// Design sprint tools
import FigmaIcon from "../../../../assets/images/technologies/Figma.svg"
import HubspotIcon from "../../../../assets/images/technologies/Hubspot.svg"
import IllustratorIcon from "../../../../assets/images/technologies/illustrator.svg"
import PhotoshopIcon from "../../../../assets/images/technologies/Photoshop.svg"
import GoogleAnalyticsIcon from "../../../../assets/images/technologies/GoogleAnalytics.svg"

// import components
import ServiceBrief from "../ServiceBrief"

export default () => {
  return (
    <ServiceBrief
      key="branding"
      heroIllustration={BrandingIllustration}
      accentColor="purple"
      alignLeft={true}
      serviceHeading="Branding"
      serviceSubheading="<span> A brand is a perception or set of associations consumers have of a business. Those perceptions originate from the story you tell about your business, the vision, the values you hold, the products and services you offer, and how your customers are transformed as a result of using those products or services. </span> </br> </br> <span>Building a brand takes work. It’s a marriage between your passion and experience and a world of data available for the taking.</span>"
      subdomains={[
        {
          heading: "Branding Tools",
          description:
            "<span>Tool we use in 8 step Brand Building process. </span>",
          tools: [
            { icon: FigmaIcon, name: "Figma" },
            { icon: GoogleAnalyticsIcon, name: "Analytics" },
            { icon: HubspotIcon, name: "Hubspot" },
            { icon: IllustratorIcon, name: "Illustrator" },
            { icon: PhotoshopIcon, name: "Photoshop" }
          ]
        }
      ]}
    />
  )
}
