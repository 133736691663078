import React from "react"

import DesignSprintIllustration from "../../../../assets/images/services/design-Sprint-illustration.svg"

// Design sprint tools
import FigmaIcon from "../../../../assets/images/technologies/Figma.svg"
import HangoutsIcon from "../../../../assets/images/technologies/Hangouts.svg"
import MiroIcon from "../../../../assets/images/technologies/Miro.svg"

// import components
import ServiceBrief from "../ServiceBrief"

export default () => {
  return (
    <ServiceBrief
      key="design-sprint"
      heroIllustration={DesignSprintIllustration}
      accentColor="red"
      alignLeft={false}
      serviceHeading="Design Sprints"
      serviceSubheading="<span> A design sprint is a five-day process that was developed at Google in 2010. It’s for validating ideas and solving big challenges through prototyping and testing ideas with customers. It’s a quick way to answer big problems that a company has without any big-time commitments. </span> </br> </br> <span>With design sprints, you can skip the product’s build and launch phase and instead discover the best solution through prototyping and validating.</span>"
      subdomains={[
        {
          heading: "Tools",
          description:
            "<span>Our preferred remote Design Sprint tools. </span>",
          tools: [
            { icon: MiroIcon, name: "Miro" },
            { icon: FigmaIcon, name: "Figma" },
            { icon: HangoutsIcon, name: "Hangout" }
          ]
        }
      ]}
    />
  )
}
