import React from "react"
import styled from "@emotion/styled"
import parse from "html-react-parser"
import systemCss from "@styled-system/css"
import { color } from "styled-system"

// Import components
import { SecondaryButton } from "../../atoms/Button"

// Media queries utils
import { sm, md, lg, xl } from "../../../utils/mediaQueries"

const ServiceBrief = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${xl} {
    align-items: flex-start;
  }

  ${lg} {
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }
`

const ServiceDetail = styled.div`
  display: flex;
  flex-basis: 50%;
  max-width: 50%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 1.5rem;
  order: ${props => (props.align ? -1 : 1)};

  ${lg} {
    order: 1;
    max-width: 100%;
    margin-top: 4rem;
  }

  ${sm} {
    margin-top: 2.4rem;
  }
`

const ServiceIllustration = styled.div`
  display: flex;
  flex-basis: 50%;
  max-width: 50%;
  padding: 0px 1.5rem;

  & > img {
    display: block;
    width: 100%;
    height: auto;
  }

  ${lg} {
    max-width: 60rem;
    align-self: center;
  }
`

const ServiceHeading = styled.h4`
  font-weight: 700;
  line-height: 1.25;
  ${systemCss({
    fontSize: "h4",
    fontFamily: "primary",
    color: "purple"
  })}

  ${color}
`

const ServiceSubheading = styled.p`
  font-weight: 400;
  line-height: 1.35;
  margin-top: 1rem;

  ${systemCss({
    fontSize: "p",
    fontFamily: "primary",
    color: "black"
  })}

  ${sm} {
    margin-top: 0.8rem;
  }
`

const SubdomainSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 4rem;
  width: 100%;

  ${sm} {
    margin-top: 2.4rem;
  }
`

const SubdomainHeading = styled.h5`
  font-weight: 700;
  line-height: 1.25;
  ${systemCss({
    fontSize: "h5",
    fontFamily: "primary",
    color: "purple"
  })}

  ${color}
`

const SubdomainSubheading = styled(ServiceSubheading)``

const TechStack = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(8.5rem, 1fr));
`

const Tool = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 0.5rem;

  & > img {
    height: 3.6rem;
    display: block;
    width: auto;
  }

  & > span {
    font-weight: 400;
    line-height: 1.23;
    margin-top: 1rem;
    text-align: center;

    ${systemCss({
      fontSize: "link",
      fontFamily: "primary",
      color: "dark"
    })}
  }
`

const CTAButton = styled(SecondaryButton)`
  margin-top: 4rem;

  &:hover {
    ${props =>
      systemCss({
        color: "white",
        bg: props.color,
        borderColor: props.color
      })}
  }

  ${sm} {
    width: 100%;
  }
`

export default ({
  alignLeft,
  accentColor,
  serviceHeading,
  serviceSubheading,
  subdomains,
  heroIllustration
}) => {
  return (
    <ServiceBrief>
      <ServiceDetail align={alignLeft}>
        <ServiceHeading color={accentColor}>{serviceHeading}</ServiceHeading>
        <ServiceSubheading>{parse(serviceSubheading)}</ServiceSubheading>

        {Array.isArray(subdomains) &&
          subdomains.map(subdomain => {
            const {
              heading: subHeading,
              description: subDescription,
              tools
            } = subdomain

            return (
              <SubdomainSection>
                <SubdomainHeading color={accentColor}>
                  {subHeading}
                </SubdomainHeading>
                <SubdomainSubheading>
                  {parse(subDescription)}
                </SubdomainSubheading>
                <TechStack>
                  {Array.isArray(tools) &&
                    tools.map((tool, i) => {
                      const { icon, name } = tool

                      return (
                        <Tool key={i}>
                          <img src={icon} alt={name} />
                          <span>{name}</span>
                        </Tool>
                      )
                    })}
                </TechStack>
              </SubdomainSection>
            )
          })}

        <CTAButton size="md" color={accentColor} borderColor={accentColor}>
          Learn More
        </CTAButton>
      </ServiceDetail>
      <ServiceIllustration>
        <img src={heroIllustration} alt={`${serviceHeading} illustration`} />
      </ServiceIllustration>
    </ServiceBrief>
  )
}
